.ratingProgress {
    height: clamp(5px, 0.6vw, 12px);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    transition: .3s;
    transform: skewX(-20deg);
}

.ratingProgressBar {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
}
.parentRatingContainer {
    background-color: #ebebeb;
    font-family: var(--font-primary);
    padding: clamp(0px, 1vw, 20px) 0px 4.6vw;
}

.styleContainer {
    padding: clamp(0px, 1vw, 20px) 0px;
}

.heading {
    display: block;
    text-align: center;
    margin: 0 clamp(30px, 6vw, 114px) clamp(10px, 1.6vw, 32px) clamp(30px, 6vw, 114px);
    object-fit: contain;
    font-size: clamp(20px, 3.12vw, 60px);
    font-weight: 900;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #35255a;
    font-family: var(--font-primary);
}

.totalRating {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 0.7px solid #372e2e;
}

.ratingContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: clamp(20px, 2.6vw, 50px);
}

.ratingHeading {
    background-image: linear-gradient(0deg, rgba(98, 37, 138, 1) 0%, rgba(179, 9, 127, 1) 100%);
    font-size: clamp(40px, 10.8vw, 208px);
    font-weight: 900;
    line-height: 0.9;
    font-style: italic;
    text-align: left;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0px clamp(20px, 2vw, 40px);
}

.ratingDesc {
    font-size: clamp(10px, 1vw, 20px);
    font-weight: 600;
    font-style: italic;
    text-transform: uppercase;
}

.ratingStarsContainer {
    display: flex;
    margin: 10px 0px 10px;
}

.ratingStars {
    position: relative;
    margin: 0px clamp(2px, 0.5vw, 10px) 0px 0px;
    width: clamp(14px, 3.3vw, 64px);
    height: clamp(14px, 3.3vw, 64px);
}

.ratingInformation {
    padding: 0px clamp(15px, 1.5vw, 30px);
}

.ratingInfoRow {
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.ratingNumber {
    font-size: clamp(12px, 1.1vw, 22px);
    font-weight: 600;
    font-style: italic;
}

.ratingInfoStarsContainer {
    display: flex;
    margin: 0px clamp(5px, 1.3vw, 25px) 0px clamp(5px, 1vw, 20px);
}

.ratingInfoStars {
    position: relative;
    width: clamp(11px, 1.6vw, 32px);
    height: clamp(11px, 1.6vw, 32px);
    margin: 0px 2px;
}

.ratingBar {
    display: flex;
    align-items: center;
}

.progressBar {
    width: clamp(80px, 13vw, 250px);
}

.percentageContainer {
    padding: 0px clamp(5px, 0.5vw, 10px);
    font-size: clamp(10px, 0.8vw, 16px);
    font-weight: 600;
    font-style: italic;
    text-align: center;
    color: #000;
}

@media screen and (max-width: 767px) {
    .heading {
        margin-bottom: 15px;
    }

    .ratingInfoRow {
        margin: 5px 0px;
    }

    .ratingDesc {
        font-style: normal;
    }

    .totalRating {
        border-right: 1px solid #ababab;
    }
}
